<template>
  <div class="task-list">
    <algolia-select
      v-model="queryString"
      :config="algoliaConfig"
      index="users"
      type="text"
      placeholder="Search name, email, type..."
      class="fxd-algolia-input"
      icon="algolia"
      icon-pack="fab"
      @focusedselected="onFocusedSelected"
      @fallback-to-firebase="onFallback"
      @movedown="scrollIntoView"
      @moveup="scrollIntoView"
    >
      <template
        slot="list"
        slot-scope="{ results, hits, loading, processing, loadMore, focused }"
      >
        <loading v-if="loading" centered />
        <no-results
          v-else-if="!$_.isEmpty(results) && !$_.get(results, '[0].nbHits')"
          icon="algolia"
          icon-pack="fab"
          title="No users found"
          class="is-vertically-centered"
        >
          <span slot="message" class="is-size-7"
            >We couldn't find any users<br />
            matching the query '{{ queryString }}'
          </span>
        </no-results>

        <div v-else>
          <list-card
            v-for="hit in hits"
            ref="cards"
            :key="hit.objectID"
            :route="routeFunc(hit)"
            :title="hit.name || ''"
            :avatar="{ name: hit.name, url: hit.avatarUrl }"
            :label="hit.label"
            :timestamp="$moment.unix(hit.dateCreated_timestamp).toDate()"
            :class="focused && focused.userId === hit.userId ? 'focused' : null"
          >
            <strong slot="title">
              <algolia-highlight v-if="hit.name" field="name" :hit="hit" />
              <algolia-highlight v-else field="email" :hit="hit" />
            </strong>

            <p
              slot="subtitle-grey"
              class="is-size-7 has-text-grey has-overflow-ellipsis-right"
            >
              <algolia-highlight field="email" :hit="hit" />
            </p>
          </list-card>

          <load-more
            v-if="!loading && !$_.get(results, '[0].isComplete', true)"
            :loading="processing"
            @loadMore="loadMore"
          />
        </div>
      </template>
    </algolia-select>
    <slot name="nosearch" v-bind="{ isEmpty: $_.isEmpty(queryString) }" />
  </div>
</template>

<script>
import { getScrollParent, isInViewport } from "@src/services/htmlElemUtils.js";

export default {
  name: "AlgoliaUserList",
  components: {
    "algolia-select": () => import("@shared/algolia/_algoliaSelect.vue"),
    "algolia-highlight": () => import("@shared/algolia/_algoliaHighlight")
  },
  props: {
    routeFunc: {
      type: Function,
      required: true
    },
    userFilter: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      queryString: "",
      params: {
        highlightPreTag: "<span class='match'>",
        highlightPostTag: "</span>",
        hitsPerPage: 20
      }
    };
  },
  computed: {
    algoliaConfig() {
      const query = {
        indexName: "users",
        query: this.queryString,
        params: this.params
      };

      switch (this.userFilter) {
        case "agents":
          return [{ ...query, filters: `isAgent=1` }];
      }

      return [query];
    },
    scrollContainer() {
      return getScrollParent(this.$el);
    }
  },
  methods: {
    scrollIntoView() {
      if (!this.$refs.cards || !this.scrollContainer) return;

      const focused = this.$refs.cards.find(i =>
        this.$_.includes(i.$el.classList, "focused")
      );

      if (focused && !isInViewport(this.scrollContainer, focused.$el, false)) {
        focused.$el.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
    },
    onFallback() {
      this.queryString = "";
    },
    onFocusedSelected(hit) {
      if (hit && this.$_.get(this.$route, "params.userId") !== hit.userId) {
        this.$router.push(this.routeFunc(hit));
      }
    }
  }
};
</script>

<style lang="scss">
.algolia-highlight {
  .match {
    font-style: normal;
    background-color: #ffff00a8;
    padding: 0.25rem 0 0.25rem 0.25rem;
  }
}
.is-vertically-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>
